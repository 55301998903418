.App {
  text-align: center;
  font-family: Spectral, Verdana, sans-serif;
  display: block;
  margin:0 auto;
  width: 100%;
  background-color: #fdf8f4;
  max-width: 900px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

sup {
  font-size: .6em;
}

.steps{
  font-size: 2em;
}

body {
  font-family: Spectral, Verdana, sans-serif;
  background-color: #fdf8f4;
}

.container {
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

h1{
  line-height: 2.4rem;
}

h3 {
  font-size: 1.5em;
}

.logo-div {
  width: 100%;
  margin-top: 1.25rem;
}

.logo {
  max-width: 110px;
  margin: 0 auto;
  padding: 10px 0 10px 0;
}

.main-header {
  width: 100%;
  font-size: 2.4rem;
  font-weight: 600;
}



/*@media screen and (min-width: 900px) {
  .logo {
    position: absolute;
    margin-left: -395px;
  }
}*/

.card {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #414344;
  box-shadow: -5px 0px 7px 2px #414344;
  margin: 2ch auto;
}

@media screen and (max-width: 600px){
  .card {
    width: 90vw;
  }
}

@media screen and (min-width: 601px){
  .card{
    width: 60ch;
  }

}

.card-image {
  width: 100%;
  max-height: 100px;
  width: auto;
  padding-top: 20px;
}

h2 {
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 2.2rem;
  padding: 0 2ch;
}

a {
  text-decoration: none;
  color: #510505;
}

a:hover {
  color: #337ab7;
}

footer {
  text-align: center;
  color: #510505;
  font-size: 1.7em;
  padding-top: 60px;
  padding-bottom: 60px;
  display: block;
  margin: 0 auto;
}

i {
  padding: 20px 15px;
}

.back-button {
  font-size: 4em;
  text-align: center;
  padding-bottom: 0px;
}

p {
  max-width: 600px;
  margin: 0 auto;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1.2em;
  text-align: justify;
}

.para-space {
  margin-bottom: 15px;
}

.no-fade {
  max-width: 600px;
  margin: 0 auto;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1s;
  animation-name: fade;
  animation-duration: 1s;
  max-width: 600px;
  margin: 0 auto;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

.slide-img {
  vertical-align: middle;
  width: 100%;
  max-height: 400px;
  max-width: 600px;
  border-radius: 25% 1%;
  margin-bottom: 15px;

}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  padding: 16px;
  background-color: transparent;
  border: none;
  color: #fdf8f4;
  font-weight: bold;
  font-size: 45px;
  transition: 0.6s ease;
  user-select: none;
}

ul {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
  color: #fdf8f4;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

li {
  display: inline;
  padding: 0 10px;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  text-align: right;
  border-top-left-radius: 75%;
  border-bottom-left-radius: 75%;
}
.prev {
  left: 0;
  border-top-right-radius: 75%;
  border-bottom-right-radius: 75%;
}

/* On hover, change background color */
/* .prev:hover, .next:hover {
  transition-property: background-color, color;
  background-color: #fdf8f4;
  color: black;
} */

.prev:hover {
  border-top-right-radius: 75%;
  border-bottom-right-radius: 75%;
}

.next:hover {
  border-top-left-radius: 75%;
  border-bottom-left-radius: 75%;
}

.line-break {
  max-width: 600px;
  text-align: center;
  margin: auto;
  margin-top: 4em;
}

button:focus {
  outline:0;
}

.soundButton {
  cursor: pointer;
  color: #510505;
  padding: 10px 20px;
  margin: 15px;
  background: none;
  border: 4px solid #510505;

  font-size: 1.2em;
  text-align: justify;
  font-family: Spectral, Verdana, sans-serif;
  border-radius: 4px;
}

.soundButton:active {
  transform: translate(2px, 2px)
}
